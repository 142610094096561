<template>
    <v-card flat class="px-8 mt-8" :style="{ 'overflow-y': 'auto', 'height': height - 250 + 'px' }">

        <div class="d-flex justify-space-between align-center pa-4 ma-2"
            :style="{ 'border-left': '10px solid', 'border-radius': '8px', 'border-color': colors.blue.accent2, 'background-color': alpha(colors.blue.accent2, 0.4) }">
            <div class="pr-4 text-overline">Parameter</div>

            <v-select label="Ausgefallene Komponente" :items="components" item-title="name" v-model="component"
                return-object="" class="pt-4 px-2">
            </v-select>
            <v-text-field class="px-2" variant="underlined" hide-details
                :label="$t('tco_component_failure__packages_per_hour')" v-model="packages_per_hour" :rules="requiredAndNumericRule">
            </v-text-field>

            <v-text-field class="px-2" variant="underlined" hide-details v-model="hours_per_day" :rules="requiredAndNumericRule"
                :label="$t('tco_component_failure__hours_per_day')">
            </v-text-field>
        </div>
        <v-row v-if="component && result">
            <v-col cols=12 md="12">
                <v-table class="pa-8">
                    <thead>
                        <tr>
                            <th class="text-left">
                            </th>
                            <th class="text-left">
                                {{$t('tco_component_failure__cost')}}
                            </th>
                            <th class="text-center">
                                {{$t('tco_component_failure__success_rate')}}
                            </th>
                            <th class="text-center">
                                {{$t('tco_component_failure__cost_per_hour')}}
                            </th>
                            <th class="text-center">
                                {{$t('tco_component_failure__cost_per_day')}}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="font-weight-bold" :class="'blue--text'">{{$t('tco_component_failure__reading_rate')}}</td>
                            <td>
                                <v-text-field hide-details v-model="costs_per_noread" type="number" step="0.05" :rules="requiredAndNumericRule"
                                    prefix="€"></v-text-field>
                            </td>
                            <td class="text-center">{{ result.noread.rate }} %</td>
                            <td class="text-center"> {{ result.noread.costs_per_hour.toLocaleString() }} €</td>
                            <td class="text-center"> {{ result.noread.costs_per_day.toLocaleString() }}€</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold" :class="'blue--text'">Dim Rate</td>
                            <td>
                                <v-text-field hide-details v-model="costs_per_nodim" type="number" step="0.05" prefix="€" :rules="requiredAndNumericRule">
                                </v-text-field>
                            </td>
                            <td class="text-center">{{ result.nodim.rate }} %
                            </td>
                            <td class="text-center">
                                {{ result.nodim.costs_per_hour.toLocaleString() }} €</td>
                            <td class="text-center">
                                {{ result.nodim.costs_per_day.toLocaleString() }}€</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold" :class="'blue--text'">Weight Rate</td>
                            <td>
                                <v-text-field hide-details v-model="costs_per_noweight" type="number" step="0.05" :rules="requiredAndNumericRule"
                                    prefix="€">
                                </v-text-field>
                            </td>
                            <td class="text-center">{{ result.noweight.rate }} %
                            </td>
                            <td class="text-center">
                                {{ result.noweight.costs_per_hour.toLocaleString() }} €</td>
                            <td class="text-center">
                                {{ result.noweight.costs_per_day.toLocaleString() }}€</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">{{$t('tco_component_failure__cost_sum')}}</td>
                            <td>
                            </td>
                            <td class="text-center" :class="'font-weight-bold'"></td>
                            <td class="text-center" :class="' font-weight-bold'">
                                {{ costs.costs_h }} €</td>
                            <td class="text-center" :class="'font-weight-bold'">
                                {{ costs.costs_d }} €</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </v-card>
</template>
        
        
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAPI } from '@/composables/useAPI';
import colors from 'vuetify/util/colors'
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { watchEffect } from 'vue';
import { useValidator } from '@/composables/useValidator';

const {requiredAndNumericRule} = useValidator()

const { height, width } = useDisplay()
const api = useAPI()
const { t } = useI18n();
const props = defineProps(['system'])

const packages_per_hour = ref(5000)
const hours_per_day = ref(8)
const costs_per_noread = ref(0.25)
const costs_per_nodim = ref(0.55)
const costs_per_noweight = ref(0.65)
const component = ref(undefined)
const result = ref(undefined)


const costs = computed(() => {
    if (result.value == undefined) return {}

    const noread = result.value.noread
    const noweight = result.value.noweight
    const nodim = result.value.nodim
    return {
        costs_h: (noread.costs_per_hour + nodim.costs_per_hour + noweight.costs_per_hour).toLocaleString(),
        costs_d: (noread.costs_per_day + nodim.costs_per_day + noweight.costs_per_day).toLocaleString(),
    }
})

const components = computed(() => {
    var components = [
        { value: "controller", name: t("tco_component_failure__controller") },
        { value: "light_sensor", name: t("tco_component_failure__lightbeam") },
        { value: "encode_decoder", name: t("tco_component_failure__encoder") },
    ]
    if (props.system.has_ident) {
        const cameras = props.system.cameras.split(',').map(v => v.toLowerCase())
        if (cameras.includes('l') || cameras.includes('r'))
            components.push({ value: "one_side_cam", name: t("tco_component_failure__1_side_camera") })
        if (cameras.includes('l') && cameras.includes('r'))
            components.push({ value: "two_side_cam", name: t("tco_component_failure__2_side_cameras") })
        if (cameras.includes('o'))
            components.push({ value: "top_camera", name: t("tco_component_failure__top_camera") })
        if (cameras.includes('u'))
            components.push({ value: "bottom_cam", name: t("tco_component_failure__bottom_camera") })
    }
    if (props.system.has_scale) {
        components.push({ value: "weight_1", name: t("tco_component_failure__weight_1") })
        components.push({ value: "weight_lft", name: t("tco_component_failure__weight_lft") })
    }
    if (props.system.has_dim) {
        if (props.system.dim_lft) {
            components.push({ value: "vms_lft", name: t("tco_component_failure__vms_lft") })
        } else {
            components.push({ value: "vms", name: t("tco_component_failure__vms") })
        }
    }
    return components
})

function alpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

watchEffect(() => {
    if (!component.value) return
    get_component_fail(component.value.value, packages_per_hour.value, hours_per_day.value, costs_per_noread.value, costs_per_nodim.value, costs_per_noweight.value)
})

function get_component_fail(component, packages_per_hour, hours_per_day, costs_per_noread, costs_per_nodim, costs_per_noweight) {
    if (!component) return
    const request = {
        packages_per_hour: packages_per_hour,
        hours_per_day: hours_per_day,
        costs_per_noread: costs_per_noread,
        costs_per_nodim: costs_per_nodim,
        costs_per_noweight: costs_per_noweight,
        failed_device: component
    }
    api.trackntraceAPI.trackntraceIcrComponentFail(props.system.id, request).then(res => {
        result.value = res.data
    })
}

</script>
      
      