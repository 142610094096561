export function useApiSchemaAdapter() {
  function mapOldToNew(schemaOld) {
    // Map values from old schema to the new schema
    const schemaNew = {
      // Mapping basic_application fields
      identifier: schemaOld.basic_application.identifier.map(val => val.toLowerCase()), // Mapping identifier
      object_reading_sides: schemaOld.basic_application.code_position, // Mapping code_position
      dimensioning: schemaOld.basic_application.volume_acquisition != "none",
      dimensioning_contour_verification:
        schemaOld.basic_application.volume_acquisition === "Deform",
      dimensioning_legal_for_trade_certification:
        schemaOld.basic_application.volume_acquisition === "VolumeLFT",

      // Mapping transport fields
      conveyor_type: schemaOld.transport.transport_type.toLowerCase(), // Mapping transport_type
      conveyor_width: schemaOld.transport.max_conveyor_width, // Mapping max_conveyor_width
      conveyor_speed: schemaOld.transport.maximal_conveyor_speed, // Mapping maximal_conveyor_speed

      object_rotation: schemaOld.transport.guidance, // Mapping guidance to object_rotation
      conveyor_object_alignment: schemaOld.transport.alignment, // Mapping alignment
      transport__rfid_dist: schemaOld.transport.rfid_dist, // Mapping rfid_dist
      transport__maximal_conveyor_object_distance:
        schemaOld.transport.maximal_conveyor_object_distance, // Mapping maximal_conveyor_object_distance

      // Mapping code_spec fields
      code_spec__rfid_type: schemaOld.code_spec.rfid_type,
      code_spec__max_tags: schemaOld.code_spec.max_tags,
      code_1d_height: schemaOld.code_spec.min_code_height,
      code_1d_module_width: schemaOld.code_spec.minimal_modul,
      code_2d_cell_width: schemaOld.code_spec.cell_size,
      code_spec__min_code_width: schemaOld.code_spec.min_code_width,
      code_spec__codetype: schemaOld.code_spec.codetype,

      // Mapping weight_spec fields
      weight_spec__weight_acquisition: schemaOld.weight_spec.weight_acquisition,
      weight_spec__weight_min: schemaOld.weight_spec.weight_min,
      weight_spec__weight_max: schemaOld.weight_spec.weight_max,

      // Mapping object_spec fields
      object_shapes: schemaOld.object_spec.object_type,
      object_length: schemaOld.object_spec.object_length,
      object_width: schemaOld.object_spec.object_width,
      object_height: schemaOld.object_spec.object_height,

      // Mapping constraint_spec fields
      constraint_spec__location: schemaOld.constraint_spec.location,
      constraint_spec__protocol: schemaOld.constraint_spec.protocol,
      constraint_spec__chassis: schemaOld.constraint_spec.chassis,
      constraint_spec__mounting: schemaOld.constraint_spec.mounting,

      // Mapping optional_extension fields
      optional_extension__software_extension:
        schemaOld.optional_extension.software_extension,
      optional_extension__analyze_option_hardware:
        schemaOld.optional_extension.analyze_option_hardware,
      optional_extension__analyze_option_software:
        schemaOld.optional_extension.analyze_option_software,
      optional_extension__additional_function:
        schemaOld.optional_extension.additional_function,
    };

    return schemaNew;
  }

  function mapIfOld(schema) {
    if (schema.basic_application) {
      return mapOldToNew(schema);
    } else {
      return schema;
    }
  }

  return { mapOldToNew, mapIfOld };
}
