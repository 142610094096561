<template>
  <div>
    <v-row justify="space-around" class="pa-5">
      <div v-for="page in pages" :key="page">
        <VuePDF :width="(width - 600) * 0.8" :pdf="pdf" :page="page">
          <v-progress-circular
            style="position: fixed; top: 25%; left: 50%"
            color="primary"
            indeterminate
            :size="100"
            :width="12"
          ></v-progress-circular>
        </VuePDF>
      </div>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import { useDisplay } from "vuetify/lib/framework.mjs";

const props = defineProps(["pdf_link"]);
const pdf_link = ref(undefined);
const { height, width } = useDisplay();

const { pdf, pages } = usePDF(pdf_link, { onProgress });
function onProgress({ loaded, total }) {
  // console.log(`${loaded / total * 100}% Loaded`)
}

onMounted(() => {
  if (props.pdf_link) {
    pdf_link.value = props.pdf_link;
  }
});
</script>
