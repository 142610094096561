<template>
  <v-textarea
    v-model="model"
    variant="outlined"
    :placeholder="props.question.placeholder_text"
    prepend-inner-icon="mdi-text"
    density="compact"
    rows="1"
    @keydown.enter.prevent="emit('chipClicked')"
  >
  </v-textarea>
</template>

<script setup>
import { defineModel } from "vue";

const props = defineProps(["question", "section_label"]);
const emit = defineEmits(["chipClicked"]);

const model = defineModel();
</script>

<style scoped></style>
