import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import { useUserStore } from "@/stores/user";
import { useSettingsStore } from "@/stores/settings";
import { useSickID } from "@/composables/useSickID";

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {

  const user = useUserStore();
  const sickID = useSickID();
  const settingsStore = useSettingsStore();

  if (to.path.startsWith("/token")) {
    await sickID.loginCallback();
    next(settingsStore.redirect_uri);
  } else if (to.path == "/login" && user.isLoggedIn) {
    next("/");
  } else if (to.meta.requiresAuth && !user.isLoggedIn) {
    next("/login"+ to.path);
  } else if(user.isLoggedIn && user.permissions.find(permission => permission == "use_systemselektor") == undefined && to.path !== "/no_permissions") {
    next("/no_permissions");
  }else if(user.isLoggedIn && user.permissions.find(permission => permission == "use_systemselektor") !== undefined && to.path == "/no_permissions") {
    next("/");
  }else {
    next();
  }
});

export default router;
