export function useS3() {

  enum Bucket {
    MEDIA = "sysel-media",
    STATIC = "sysel-static",
    PUBLIC = "public",
  };

  function get(bucket: Bucket, filename: String) {
    return import.meta.env.VITE_APP_S3 + bucket + "/" + filename
  }

  return { get, Bucket }
}
