/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
import router from '@/router'

//Libs 
const app = createApp(App)
registerPlugins(app)

//Own Elements
import ProjectDeletePrompt from "@/components/ProjectDeletePrompt.vue"

app.component("ProjectDeletePrompt", ProjectDeletePrompt)

router.isReady().then(() => {
    app.mount('#app');
});
