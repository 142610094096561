<template>
  <template
    v-for="(question, i) in section.questions"
    :key="question.label"
    class="pl-3"
  >
    <div v-show="checkVisibility(question)">
      <v-divider v-if="i != 0" class="my-5"></v-divider>
      <v-row
        dense
        align="center"
        justify="space-between"
        class="ml-0 pb-5 pt-1 mr-0"
      >
        <v-label>
          {{ getQuestionLabel(section, question) }}
        </v-label>
        <div>
          <v-icon
            color="primary"
            size="small"
            v-if="question.highlight_level === '2'"
          >
            mdi-star
          </v-icon>
          <v-icon
            color="primary"
            size="small"
            v-if="question.highlight_level === '1'"
          >
            mdi-star-outline
          </v-icon>
          <v-tooltip activator="parent" location="bottom">
            {{ $t("system_request__question_filter_icon_tooltip") }}
          </v-tooltip>
        </div>
      </v-row>

      <v-row dense align="center" justify="space-between">
        <v-col v-if="question.image" :cols="2">
          <v-img width="60" :src="question.image" color="white" />
        </v-col>
        <v-col :cols="calculateQuestionSpace(question)">
          <BaseBinaryInputField
            v-if="question.type === 'binary-input'"
            v-model="request[question.data_field]"
            :question="question"
            @switchClicked="requestStore.get_solutions()"
          >
          </BaseBinaryInputField>
          <BaseTextInputField
            v-else-if="question.type === 'text-input'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions()"
          />
          <BaseSlider
            v-else-if="question.type === 'slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions()"
          />
          <BaseSliderRanged
            v-else-if="question.type === 'range-slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions()"
          />
          <BaseSelect
            v-else
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions()"
          />
        </v-col>
        <v-col v-if="showInfo(question) !== '' || question.help_url" :cols="1">
          <v-menu open-on-hover>
            <template v-slot:activator="{ props }">
              <v-icon
                color="blue"
                size="default"
                v-bind="props"
                :style="{ cursor: 'pointer' }"
              >
                mdi-information
              </v-icon>
            </template>
            <v-list style="background-color: #3f3f3f; color: white">
              <v-list-item>
                <v-list-item-title
                  style="white-space: pre-line; font-size: 14px"
                >
                  {{ showInfo(question) }}
                  <br v-if="question.help_url && showInfo(question)" />
                  <br v-if="question.help_url && showInfo(question)" />
                  <span v-if="question.help_url !== ''">
                    {{ $t("systems_request_help_url") }}
                    <v-icon
                      size="default"
                      style="cursor: pointer"
                      @click="showInfoPage(question)"
                      color="blue"
                    >
                      mdi-link
                    </v-icon>
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
  </template>
</template>

<script setup>
import { ref, computed } from "vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseSlider from "@/components/BaseSlider.vue";
import BaseSliderRanged from "@/components/BaseSliderRanged.vue";
import BaseTextInputField from "@/components/BaseTextInputField.vue";
import BaseBinaryInputField from "@/components/BaseBinaryInputField.vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { useI18n } from "vue-i18n";

const { t, te } = useI18n();

const settings = useSettingsStore();
const requestStore = useSystemRequestStore();
const { request } = storeToRefs(useSystemRequestStore());
const props = defineProps(["section"]);

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function showInfo(question) {
  if (settings.locale === "en") {
    return question.help_text_en;
  } else if (settings.locale === "de") {
    return question.help_text_de;
  }
}

function showInfoPage(question) {
  window.open(question.help_url, "_blank");
}

function calculateQuestionSpace(question) {
  let cols = 12;
  if (question.image) {
    cols -= 2;
  }
  if (showInfo(question) !== "" || question.help_url) {
    cols -= 1;
  }
  return cols;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}

function getQuestionLabel(section, question) {
  let label;
  if (te("system_request_" + section.label + "_" + question.label)) {
    label = t("system_request_" + section.label + "_" + question.label);
  } else {
    label = question[settings.locale];
  }
  // if (label.slice(-1) === "*") {
  //   return label.slice(0, label.length - 1);
  // }
  return label;
}

// function showFilterIcon(section, question) {
//   let label;
//   if (te("system_request_" + section.label + "_" + question.label)) {
//     label = t("system_request_" + section.label + "_" + question.label);
//   } else {
//     label = question[settings.locale];
//   }
//   if (label.slice(-1) === "*") {
//     // return {
//     //   color: "#000000",
//     // };
//     return true;
//   }
//   // return {}
//   return false;
// }
</script>
