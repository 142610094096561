import axios from "axios";
const api_url = import.meta.env.VITE_APP_API

const instance = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  },
});


// instance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// instance.defaults.xsrfCookieName = "csrftoken";
export default instance;