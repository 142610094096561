import { useI18n } from 'vue-i18n'

export function useValidator() {
    const { t } = useI18n()

    const name_required_rules = [
        v => !!v || t('validator__rule_name_required'),
    ]

    const customer_required_rules = [
        v => !!v || t('validator__rule_name_required'),
    ]

    const reading_rate_rules = [
        v => !!v || "This field is required",
        v => (v && v <= 100) || "Cannot be > 100%",
        v => (v && v >= 0) || "Cannot be < 0%"
    ]
    const hours_per_day_rules = [
        v => !!v || "This field is required",
        v => (v && v <= 24) || "Cannot be > 24h",
        v => (v && v >= 1) || "Cannot be <= 1h"
    ]

    const requiredAndNumericRule = [
        v => !!v || 'Wert wird benötigt',
        v => v >= 0 || 'Wert muss >= 0 sein'
    ]

    return { name_required_rules, customer_required_rules, reading_rate_rules, hours_per_day_rules, requiredAndNumericRule }
}