<template>
    <div class="d-flex flex-row ">
        <v-tabs v-model="tab" direction="vertical" color="primary" class="pt-8">
            <v-tooltip :text="$t(s.title)" location="right" v-for="s in sections">
                <template v-slot:activator="{ props }">
                    <v-tab :value="s.id" v-bind="props" class="my-3">
                        <v-img :src="s3.get(s3.Bucket.PUBLIC, s.icon)"></v-img>
                    </v-tab>
                </template>
            </v-tooltip>
        </v-tabs>

        <v-window v-model="tab" v-if="system" class="flex-grow-1 align-self-center">
            <v-window-item value="reading_page">
                <TcoReadingRate :system="system" />
            </v-window-item>
            <v-window-item value="lifecycle">
                <TcoLifecycle :system="system" />
            </v-window-item>
            <v-window-item value="component_failure">
                <TcoComponent :system="system" />
            </v-window-item>
        </v-window>
    </div>
</template>
    
    
<script setup>
import TcoComponent from '@/components/TcoComponent.vue';
import TcoLifecycle from '@/components/TcoLifecycle.vue';
import TcoReadingRate from '@/components/TcoReadingRate.vue';
import { ref, computed } from 'vue'
import { useS3 } from '@/composables/useS3';
const props = defineProps(['system'])


const s3 = useS3()
const sections = computed(() => {
    var sections = [
        { id: "reading_page", title: "tco__simluation_reading_rate", icon: "magnifier.svg" },
    ]
    if (['icr', 'lector', 'ops'].includes(props.system.type.toLowerCase())) {
        sections.push({ id: "lifecycle", title: "tco__costs_lifecycle", icon: "circle.svg" })
        sections.push({ id: "component_failure", title: "tco__component_failure", icon: "tool.svg" })
    }
    return sections
})

const tab = ref("reading_page")

</script>
  
<style scoped>
div.v-responsive.v-img {
    position: static;
}

.v-tab.v-tab {
    height: 38px;
}
</style>