<template>
  <div class="flexbox_container">
    <v-toolbar flat density="compact">
      <v-btn icon="mdi-chevron-left" @click="emit('unselected')"></v-btn>
      <v-toolbar-title>{{ props.system.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-chip
        v-if="system.standard"
        color="success"
        variant="flat"
        class="ma-2"
      >
        <v-icon start icon="mdi-check"></v-icon>
        {{ $t("system_detail__standard_system") }}
      </v-chip>

      <v-tooltip :text="$t('system_detail__backend_link')" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            v-if="user.is_staff"
            class="ma-2"
            variant="icon"
            icon="mdi-database-edit"
            :href="link"
            target="_blank"
          ></v-btn>
        </template>
      </v-tooltip>
      <v-btn
        v-if="project_active"
        variant="text"
        :color="system_is_favourite"
        icon="mdi-star"
        @click="set_favourite"
      ></v-btn>
    </v-toolbar>

    <div class="text-right pa-0">
      <v-tabs v-model="tab" color="primary" align-tabs="center">
        <v-tooltip :text="$t(s.title)" location="bottom" v-for="s in sections">
          <template v-slot:activator="{ props }">
            <v-tab :value="s.id" v-bind="props">
              <v-icon size="x-large">{{ s.icon }}</v-icon>
            </v-tab>
          </template>
        </v-tooltip>
      </v-tabs>
    </div>
    <v-window v-model="tab" v-if="system" class="scrollable_content">
      <v-window-item value="info">
        <SolutionDetails :system="system" />
      </v-window-item>
      <v-window-item value="tco">
        <tco :system="system"></tco>
      </v-window-item>
      <v-window-item value="drawing">
        <SolutionPdfViewer :pdf_link="system.drawing" />
      </v-window-item>
      <v-window-item value="wiring">
        <SolutionPdfViewer :pdf_link="system.wiring" />
      </v-window-item>
      <v-window-item value="eDraw_html">
        <iframe
          id="3d"
          :src="system.eDraw_html"
          width="100%"
          :height="iframe_height()"
          frameborder="0"
        ></iframe>
      </v-window-item>
      <v-window-item value="downloads">
        <SolutionDownloads id="downloads" :system="system" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useUserStore } from "@/stores/user";
import SolutionPdfViewer from "@/components/SolutionPdfViewer.vue";
import Tco from "@/components/Tco.vue";
import SolutionDetails from "@/components/SolutionDetails.vue";
import SolutionDownloads from "@/components/SolutionDownloads.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";

const props = defineProps(["system"]);
const emit = defineEmits(["unselected"]);

const { height } = useDisplay();
const user = useUserStore();
const tab = ref("info");
const systemRequestStore = useSystemRequestStore();
const { project, project_active } = storeToRefs(systemRequestStore);

const link = computed(() => {
  return `${window.location.origin}/api/admin/${
    props.system.type.toLowerCase() === "clv"
      ? "ops"
      : props.system.type.toLowerCase()
  }/standardsystem/${props.system.id}/change/`;
});

const system_is_favourite = computed(() => {
  if (
    project.value &&
    props.system &&
    project.value.favourite == props.system.id
  ) {
    return "yellow";
  }
  return "default";
});

// TODO: this is partially hardcoded (-230px), make dynamic!
function iframe_height() {
  return height.value - 230;
}

function set_favourite() {
  if (!project_active) {
    return;
  }
  if (project.value.favourite == props.system.id) {
    project.value.favourite = null;
  } else {
    project.value.favourite = props.system.id;
  }
  systemRequestStore.update_favourite();
}

const sections = computed(() => {
  if (!props.system) return [];
  var section = [];
  section.push({
    id: "info",
    title: "systems__tab_system_info",
    icon: "mdi-card-bulleted-outline",
  });
  if (props.system.has_tco_support && user.permissions.includes("use_tco"))
    section.push({
      id: "tco",
      title: "systems__tab_tco",
      icon: "mdi-chart-areaspline",
    });
  if (props.system.drawing)
    section.push({
      id: "drawing",
      title: "systems__tab_technical_drawing",
      icon: "mdi-chart-tree",
    });
  if (props.system.wiring)
    section.push({
      id: "wiring",
      title: "systems__tab_technical_circuit",
      icon: "mdi-power-plug",
    });
  if (props.system.eDraw_html)
    section.push({
      id: "eDraw_html",
      title: "systems__tab_3d_model",
      icon: "mdi-video-3d",
    });
  section.push({
    id: "downloads",
    title: "Downloads",
    icon: "mdi-file-download-outline",
  });
  return section;
});
</script>

<style scoped>
.flexbox_container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 125px);
}

.scrollable_content {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 125px);
}
</style>
