<template>
	<div>
		<v-card flat class="px-8 mt-8" :style="{'height': height - 250 + 'px' }">
			<v-row dense justify="center">
				<v-col cols="12">
					<v-row justify="center">
						<v-col cols="12" lg="5">
							<v-table>
								<thead>
									<tr>
										<th class="text-left"></th>
										<th class="text-center">{{ $t('tco_lifecycle__costs') }}</th>
										<th class="text-center">{{ $t('tco_lifecycle__cost_split_from_msrp') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in data_split[0]" :key="item.name">
										<td class="font-weight-bold text-blue" :class="item.bold && ''">
											{{ item.name }}
										</td>
										<td class="text-center font-weight-bold">
											{{ item.system_costs }} €
										</td>
										<td class="text-center font-weight-bold'">
											{{ item.service_percentage }} %
										</td>
									</tr>
								</tbody>
							</v-table>
						</v-col>
						<v-col cols="12" md="5" v-if="lgAndUp">
							<v-table>
								<thead>
									<tr>
										<th class="text-left"></th>
										<th class="text-center">{{ $t('tco_lifecycle__costs') }}</th>
										<th class="text-center">{{ $t('tco_lifecycle__cost_split_from_msrp') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in data_split[1]" :key="item.name">
										<td class="font-weight-bold text-blue">
											{{ item.name }}
										</td>
										<td class="text-center">
											{{ item.system_costs }} €
										</td>
										<td class="text-center">
											{{ item.service_percentage }} %
										</td>
									</tr>
								</tbody>
							</v-table>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

		<v-row dense justify="center">
			<v-col cols="9" lg="8" xl="7" xxl="5">
					<apexchart  type="bar" :options="chartOptions" :series="series"></apexchart>
			</v-col>
		</v-row>
	</v-card>

	</div>
</template>
    
    
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAPI } from '@/composables/useAPI';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import colors from 'vuetify/util/colors'
import { useSettingsStore } from '@/stores/settings';

const settings = useSettingsStore()
const { lgAndUp, height } = useDisplay()
const api = useAPI()
const { t } = useI18n();
const props = defineProps(['system'])
const time_series = ref([])
const service_costs = ref([])
const repair_costs = ref([])
const system_costs = ref([])

const chunk = (arr, n) => arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [];

const data_split = computed(() => {
	if (table_data.value.length == 0) return []
	return chunk(table_data.value.slice(1), lgAndUp.value ? 4 : 100);
})

const labels = computed(() => {
	return time_series ? time_series.value.map((x) => x + " " + t('tco_lifecycle__year')) : []
})

const table_data = computed(() => {
	return labels.value.map((v, i) => {
		const system_costs = parseInt(props.system.costs.service) + parseInt(props.system.costs.system)
		return {
			name: t('tco_lifecycle__after_n_years', { years: v }),
			system_costs: system_costs.toLocaleString(),
			service_percentage: (100 / Number(props.system.costs.system) * service_costs.value[i]).toFixed(1)
		}
	})
})

const series = computed(() => {
	return [
		{
			name: t('tco_lifecycle__system_costs'),
			data: system_costs.value,
		},
		{
			name: t('tco_lifecycle__service_costs'),
			data: service_costs.value,
		},
	]
})

const chartOptions = computed(() => {
	return {
		chart: {
			type: 'bar',
			stacked: true,
			background: '#00000'
		},
		theme: {
			mode: settings.getDarkMode,
		},
		colors: [colors.blue.accent2, colors.orange.accent2,],
		plotOptions: {
			bar: {
				horizontal: false,
				dataLabels: {
					total: {
						enabled: false,
						style: {
							colors: [colors.blue.accent2,],
							fontSize: '17px',
							fontWeight: 900,
						}
					},

				}
			},
		},
		dataLabels: {
			formatter: function (val, opt) {
				return val.toLocaleString() + '€'
			},
		},
		xaxis: {
			categories: labels.value,
			stepSize: 1,
			min: 0,
			labels: {
				formatter: function (val) {
					return val + " " + t('tco_reading_rate__years')
				}
			}
		},
		yaxis: {
			labels: {
				formatter: (val) => {
					return val.toLocaleString() + '€'
				}
			}
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val.toLocaleString() + '€'
				}
			}
		},
		fill: {
			opacity: 1
		}
	}
})

onMounted(() => {
	const system_type = props.system.type.toLowerCase()
	if (system_type == 'icr') {
		api.trackntraceAPI.trackntraceIcrLifecycle(props.system.id, {}).then(res => {
			system_costs.value = Array(9).fill(res.data.system_costs);
			time_series.value = res.data.time_series
			service_costs.value = res.data.service_costs
			repair_costs.value = res.data.repair_costs
		})
	} else if (system_type == 'lector') {
		api.trackntraceAPI.trackntraceLectorLifecycle(props.system.id, {}).then(res => {
			system_costs.value = Array(9).fill(res.data.system_costs);
			time_series.value = res.data.time_series
			service_costs.value = res.data.service_costs
			repair_costs.value = res.data.repair_costs
		})
	} else if (system_type == 'ops') {
		api.trackntraceAPI.trackntraceOpsLifecycle(props.system.id, {}).then(res => {
			system_costs.value = Array(9).fill(res.data.system_costs);
			time_series.value = res.data.time_series
			service_costs.value = res.data.service_costs
			repair_costs.value = res.data.repair_costs
		})
	}
})
</script>

<style></style>
  
  