<template>
  <form @submit="emit('sliderReleased')">
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Min."
          variant="outlined"
          v-model="model[0]"
          :suffix="unit"
          density="compact"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Max."
          variant="outlined"
          v-model="model[1]"
          :suffix="unit"
          density="compact"
          @input.native="emit('sliderReleased')"
        ></v-text-field>
      </v-col>
    </v-row>
  </form>

  <v-row class="mx-3">
    <v-range-slider
      :step="props.question.step"
      :max="props.question.max"
      :min="props.question.min"
      v-model="model"
      color="primary"
      @end="emit('sliderReleased')"
    >
    </v-range-slider>
  </v-row>
</template>

<script setup>
import { defineModel, computed } from "vue";

const unit = computed(() => {
  return props.question.unit;
});

const emit = defineEmits(["sliderReleased"]);
const model = defineModel();
const props = defineProps(["question", "section_label"]);
</script>

<style scoped>
.v-chip.v-chip--density-default {
  background-color: #007fc3;
}
</style>
