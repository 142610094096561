<template>
    <v-card class="pa-8 mt-8" :style="{'height': height - 200 + 'px' }">
        <v-card-text class="text-center" >
            <v-row justify="space-around">
                <v-col cols="9" lg="8" xl="7" xxl="5">
                    <apexchart :options="options" :series="series"></apexchart>
                </v-col>
            </v-row>
            <div class="pa-4">
                <div class="d-flex justify-space-between align-center ma-4 pa-2">
                    <v-text-field class="px-2" variant="underlined" hide-details
                        :label="$t('tco_reading_rate__throuput_per_hour')" v-model="packages_per_hour" type="number"
                        step="100" onkeypress="return event.charCode >= 48" min="0" suffix="p/h">
                    </v-text-field>

                    <v-text-field class="px-2" variant="underlined" hide-details label="Stunden pro Tag"
                        v-model="hours_per_day" type="number" step="1" onkeypress="return event.charCode >= 48" min="1"
                        max="24" suffix="h" :rules="hours_per_day_rules">
                    </v-text-field>

                    <v-select class="px-2" variant="underlined" hide-details :items="measurement_units"
                        :label="$t('tco_reading_rate__unit')" v-model="measurement_unit" item-title="name" item-value="key"
                        return-object @update:model-value="(measurement_count = measurement_unit.default)"></v-select>

                    <v-text-field class="px-2" variant="underlined" hide-details
                        :label="$t('tco_reading_rate__count') + ' ' + measurement_unit.name" v-model="measurement_count"
                        type="number" step="1" :min="measurement_unit.min" :max="measurement_unit.max"
                        onkeypress="return event.charCode >= 48">
                    </v-text-field>
                </div>


                <div v-for="c in measurements.filter(x => !x.disable)"
                    class="d-flex justify-space-between align-center pa-4 ma-2"
                    :style="{ 'border-left': '10px solid', 'border-radius': '8px', 'border-color': c.color, 'background-color': alpha(c.color, 0.4) }">
                    <div class="pr-4 text-overline">{{ c.title }}</div>
                    <v-text-field class="px-2" variant="underlined" hide-details
                        :label="$t('tco_reading_rate__costs_in_eur')" v-model="c.cost_no" type="number" step="0.05"
                        prefix="€">
                    </v-text-field>
                    <v-text-field class="px-2" variant="underlined" hide-details :rules="reading_rate_rules"
                        v-model="c.reading_rate" type="number" step="0.05" :label="$t('tco_reading_rate__rate')" prefix="%"
                        :max="c.reading_rate_2" min="0">
                    </v-text-field>
                    <v-text-field class="px-2" variant="underlined" hide-details :rules="reading_rate_rules"
                        v-model="c.reading_rate_2" type="number" step="0.05" :label="$t('tco_reading_rate__rate_2')"
                        prefix="%" max="100" :min="c.reading_rate">
                    </v-text-field>
                    <v-btn class="px-2" v-if="measurements.filter(x => !x.disable).length > 1" variant="text"
                        :icon="c.show ? 'mdi-eye' : 'mdi-eye-closed'" @click="c.show = !c.show"></v-btn>
                </div>
            </div>

        </v-card-text>
    </v-card>
</template>
    
    
<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import colors from 'vuetify/util/colors'
import { useValidator } from "@/composables/useValidator"
import { useDisplay } from 'vuetify/lib/framework.mjs';
const { width, height } = useDisplay()
import { useSettingsStore } from '@/stores/settings';

const settings = useSettingsStore()
const { t } = useI18n();
const { reading_rate_rules, hours_per_day_rules } = useValidator()
const props = defineProps(['system'])

const measurements = ref([
    { title: t('tco_reading_rate__costs_no_read'), color: colors.blue.accent2, cost_no: 0.1.toFixed(2), reading_rate: 99.5, reading_rate_2: 99.8, show: true, disable: !props.system.has_ident },
    { title: t('tco_reading_rate__costs_no_dim'), color: colors.orange.accent2, cost_no: 0.5.toFixed(2), reading_rate: 99.6, reading_rate_2: 99.7, show: true, disable: !props.system.has_dim },
    { title: t('tco_reading_rate__costs_no_weight'), color: colors.teal.accent2, cost_no: 0.6.toFixed(2), reading_rate: 99.8, reading_rate_2: 99.9, show: true, disable: !props.system.has_scale },
])

const measurement_units = [
    { name: t('tco_reading_rate__days'), key: "days", default: 7, min: 1, max: 365, factor: 1 },
    { name: t('tco_reading_rate__years'), key: "years", default: 3, min: 1, max: 25, factor: 365 }
]
const measurement_unit = ref(measurement_units[0])
const packages_per_hour = ref(5000)
const hours_per_day = ref(24)
const measurement_count = ref(7)

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

function alpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

function get_costs(x, costs_per_failure, reading_rate_1, reading_rate_2) {
    return cost_range(measurement_unit.value.key == 'days' ? x : x * 365, costs_per_failure, reading_rate_1, reading_rate_2)
}

function cost_range(d, costs_per_failure, reading_rate_1, reading_rate_2) {
    const b = ((d * packages_per_hour.value * hours_per_day.value) * ((100 - reading_rate_2) / 100) * costs_per_failure)
    const a = ((d * packages_per_hour.value * hours_per_day.value) * ((100 - reading_rate_1) / 100) * costs_per_failure)
    return { x: d, y: [b, a] }
}

const series = computed(() => {
    return measurements.value.filter(c => c.show && !c.disable).map(c => (
        {
            type: 'rangeArea',
            name: c.title,
            data: range(0, measurement_count.value, 1)
                .map(x => get_costs(x, c.cost_no, c.reading_rate, c.reading_rate_2))
        }
    ))
})


const options = computed(() => {
    return {
        chart: {
            type: 'rangeArea',
            animations: {
                enabled: false
            },
            background: '#00000'
        },
        theme:{
			mode: settings.getDarkMode, 
		},
        colors: measurements.value.filter(c => c.show && !c.disable).map(c => c.color),
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'straight',
            width: [1]
        },
        legend: {
            show: true,
            customLegendItems: measurements.value.filter(c => c.show && !c.disable).map(c => c.title),
        },

        markers: {
            hover: {
                sizeOffset: 5
            }
        },
        dataLabels: {
            formatter: function (val, opt) {
                return val.toLocaleString() + '€'
            },
        },
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val.toLocaleString() + '€'
                }
            }
        },
        xaxis: {
            labels: {
                formatter: (val) => {
                    var v = val
                    if (measurement_unit.value.key == 'years') {
                        v = v / 365
                    }
                    return v.toLocaleString() + " " + measurement_unit.value.name

                }
            }
        },
        annotations: {
            yaxis: [
                {
                    y: parseInt(props.system.costs.service) + parseInt(props.system.costs.system),
                    borderColor: colors.red.accent1,
                    borderWidth: 2,
                    strokeDashArray: 0,
                    label: {
                        position: 'left',
                        borderColor: colors.red.accent1,
                        style: {
                            color: '#fff',
                            background: colors.red.accent1,
                        },
                        text: t('tco_reading_rate__system_costs')
                    }
                }
            ]
        }
    }
})


</script>
  
  