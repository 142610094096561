<template>
  <v-alert
    :title="notification_center.recent.title"
    v-if="notification_center.has_notifications"
    vertical
    variant="tonal"
    :type="get_type(notification_center.recent)"
    :text="
      notification_center.recent.subtitle
        ? notification_center.recent.subtitle
        : ''
    "
    style="position: fixed; bottom: 75px; left: 25px; right: 25px; z-index: 100"
  >
  </v-alert>
</template>

<script setup lang="ts">
import {
  use_notification_center,
  NotificationType,
} from "@/stores/notification_center";

const notification_center = use_notification_center();

function get_type(notification) {
  switch (notification.type) {
    case NotificationType.Success:
      return "success";
    case NotificationType.Info:
      return "info";
    case NotificationType.Warning:
      return "warning";
    case NotificationType.Error:
      return "error";
  }
}
</script>
