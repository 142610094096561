<template>
  <v-bottom-sheet v-model="dialog" inset>
    <v-card>
      <v-form v-model="valid" @submit.prevent="save_clicked">
        <v-card-text>
          <v-text-field
            autofocus
            :rules="validator.name_required_rules"
            :label="$t('project_prompt__name')"
            v-model="item.name"
          ></v-text-field>
          <v-text-field
            :label="$t('project_prompt__description')"
            v-model="item.description"
          ></v-text-field>
          <v-text-field
            :rules="validator.customer_required_rules"
            :label="$t('project_prompt__customer')"
            v-model="item.customer"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel_clicked">{{
            $t("project_prompt__cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="!valid" color="primary">{{
            $t("project_prompt__save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-bottom-sheet>
</template>

<script setup>
import { ref } from "vue";
import { useValidator } from "@/composables/useValidator";

defineExpose({ open });

const validator = useValidator();
const item = ref(null);
const dialog = ref(false);
var resolve = null;
var reject = null;
const valid = ref(false);

function open(_item) {
  return new Promise((resolve_, reject_) => {
    resolve = resolve_;
    reject = reject_;
    item.value = _item;
    dialog.value = true;
  });
}

function cancel_clicked() {
  dialog.value = false;
  reject();
}

function save_clicked() {
  dialog.value = false;
  resolve(item.value);
}
</script>
