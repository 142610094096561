import router from "@/router";
import { UserManager, Log } from "oidc-client-ts";
import { useUserStore } from "@/stores/user";
import { useSettingsStore } from "@/stores/settings";
import { useAPI } from "@/composables/useAPI";

Log.setLogger(console);
Log.setLevel(Log.ERROR);
const authority = import.meta.env.VITE_APP_OIDC;
const client_id = import.meta.env.VITE_APP_OIDC_NAME;
const redirect_uri = import.meta.env.VITE_APP_CALLBACK;

export function useSickID() {
  var conf = {
    authority: authority,
    client_id: client_id,
    redirect_uri: redirect_uri,
    automaticSilentRenew: true,
  };

  var userManger = new UserManager(conf);
  const settingsStore = useSettingsStore();
  const store = useUserStore();

  userManger.events.addUserLoaded((usr) => {
    useAPI()
      .request.post("/token/oid/sysel", {
        access_token: usr.access_token,
      })
      .then(useAPI().authenticate)
      .then(() => {
        window.history.replaceState({}, document.title, "/");
      });
  });

  userManger.events.addUserSignedIn(() => {
    router.push("/");
  });

  userManger.events.addUserSignedOut((usr) => {
    router.push("/login");
  });

  userManger.events.addUserUnloaded((cbusr) => {
    router.push("/login");
    localStorage.clear();
    sessionStorage.clear();
  });

  function login(url) {
    settingsStore.redirect_uri = url;
    // conf.redirect_uri = conf.redirect_uri + url;
    // userManger = new UserManager(conf);
    // console.log(conf.redirect_uri);
    return userManger.signinRedirect();
  }

  function loginCallback(to) {
    return userManger
      .signinRedirectCallback()
      .then(function (usr) {
        store.setSickIDCredentials(usr.access_token, usr.refresh_token);

        return useAPI().request.post("/token/oid/sysel", {
          access_token: usr.access_token,
        });
      })
      .then(useAPI().authenticate);
  }

  function logout() {
    this.userManger.clearStaleState();
    this.userManger.removeUser();
  }

  return {
    login,
    loginCallback,
    logout,
  };
}
