import axiosInstance from "@/services/api";
import { useUserStore } from "@/stores/user";
import { useSettingsStore } from "@/stores/settings";
import router from "@/router";
import { useProgressBar } from "./useProgressBar";
import {
  TokenApi,
  UserApi,
  TrackntraceApi,
  ProjectApi,
  GroupApi,
  ShortenApi,
  SurveyApi,
} from "systemselektor-api";

const basePath = import.meta.env.VITE_APP_API || "http://localhost/api";

const loading = useProgressBar().active;
const tokenAPI = new TokenApi(undefined, basePath, axiosInstance);
const userAPI = new UserApi(undefined, basePath, axiosInstance);
const projectAPI = new ProjectApi(undefined, basePath, axiosInstance);
const trackntraceAPI = new TrackntraceApi(undefined, basePath, axiosInstance);
const groupAPI = new GroupApi(undefined, basePath, axiosInstance);
const shortenApi = new ShortenApi(undefined, basePath, axiosInstance);
const surveyApi = new SurveyApi(undefined, basePath, axiosInstance);

export function useAPI() {
  const UserStore = useUserStore();

  axiosInstance.interceptors.request.use(
    (config) => {
      loading.value = true;
      const token = UserStore.get_backend_access_token;
      if (token) config.headers["Authorization"] = "Bearer " + token;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      loading.value = false;
      return res;
    },
    async (err) => {
      loading.value = false;
      const originalConfig = err.config;
      if (originalConfig.url !== "token/refresh/" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const data = {
              refresh: UserStore.get_backend_refresh_token,
            };
            const result = await new TokenApi().tokenRefreshCreate(data);
            const accessToken = result.data.access;
            UserStore.setBackendAccessToken(accessToken);
            return axiosInstance(originalConfig);
          } catch (_error) {
            UserStore.clear();
            router.push("/login");
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );

  function login(username, password) {
    const user = {
      username: username.value,
      password: password.value,
    };
    return tokenAPI.tokenCreate(user).then(authenticate);
  }

  function authenticate(loginResult) {
    UserStore.setBackendCredentials(
      loginResult.data.access,
      loginResult.data.refresh
    );
    return userAPI
      .userMe()
      .then((res) => {
        const d = res.data;
        UserStore.setUserinformation(
          d.first_name,
          d.last_name,
          d.username,
          d.is_active,
          d.is_staff,
          d.is_superuser,
          d.id,
          d.groups,
          d.permissions
        );
      })
      .then((_) => {
        return true;
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const request = axiosInstance;
  return {
    request,
    loading,
    authenticate,
    login,
    trackntraceAPI,
    userAPI,
    projectAPI,
    groupAPI,
    shortenApi,
    surveyApi,
  };
}
