<template>
  <div v-show="props.show" class="flexbox_container">
    <v-toolbar flat density="compact">
      <v-toolbar-title
        >{{ $t("system_solutions") }}
        <span v-if="project" class="font-weight-light">
          ({{ $t("systems__project_title") }} {{ project.name }})
        </span>
      </v-toolbar-title>

      <v-chip class="mx-3" color="primary" variant="flat">
        {{ solutions.length }} Solutions
      </v-chip>

      <v-menu v-model="showMenu" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon>mdi-table-cog</v-icon>
          </v-btn>
        </template>

        <v-sheet class="pa-5" width="500px">
          <v-row>
            <v-col>
              <v-label>{{ $t("systems_result_table__show_columns") }}</v-label>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0">
              <v-chip-group
                mandatory
                v-model="selectedColumns"
                column
                selected-class="text-primary"
                multiple
                filter
                label="test"
              >
                <v-chip v-for="q in headers" filter variant="tonal" :value="q">
                  {{ $t(q.title) }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-label>{{ $t("systems_result_table__table_options") }}</v-label>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0">
              <v-switch
                v-model="group_solutions"
                hide-details
                :label="t('systems_result_table__group_solutions')"
                color="primary"
              ></v-switch>
            </v-col>
          </v-row>
        </v-sheet>
      </v-menu>

      <v-tooltip
        v-if="group_solutions"
        :text="t('system_request__toggle_solution_table')"
      >
        <template v-slot:activator="{ props }">
          <VBtn
            v-bind="props"
            :icon="
              allGroupsOpen()
                ? 'mdi-unfold-less-horizontal'
                : 'mdi-unfold-more-horizontal'
            "
            @click="allGroupsOpen() ? closeAll() : openAll()"
          >
          </VBtn>
        </template>
      </v-tooltip>
    </v-toolbar>

    <div class="centered_container" v-if="items.length == 0">
      <span>
        {{ $t("system_request__no_data") }}
        <v-btn
          @click="sendEmail"
          density="compact"
          variant="plain"
          icon="mdi-email-outline"
        >
        </v-btn>
      </span>
    </div>

    <div class="scrollable_content" v-show="items.length > 0">
      <v-data-table
        :items="items"
        items-per-page="-1"
        item-value="index"
        item-key="index"
        :headers="activeHeaders"
        :sort-by="sort_by"
        :group-by="group_by"
        @click:row="open_details"
        no-data-text=""
        :row-props="rowItemClass"
      >
        <template
          v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }"
        >
          <tr>
            <template v-for="column in columns" :key="column.key">
              <th
                @mouseover="showSortIcon = true"
                @mouseleave="showSortIcon = false"
                @click="() => toggleSort(column)"
              >
                {{ t(column.title) }}
                <v-icon
                  v-if="isSorted(column)"
                  :icon="getSortIcon(column)"
                ></v-icon>
              </th>
            </template>
          </tr>
        </template>

        <template
          v-slot:group-header="{ columns, item, toggleGroup, isGroupOpen }"
        >
          <tr>
            <td :colspan="columns.length">
              <VBtn
                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                :class="'ml-' + String(item.depth * 5)"
                size="small"
                variant="text"
                :ref="
                  (el) =>
                    (toggleExpandBtns[item.value] = {
                      item,
                      toggleGroup,
                      isGroupOpen,
                    })
                "
                :data-open="isGroupOpen(item)"
                @click="toggleGroup(item)"
              ></VBtn>
              {{ item.value }} ({{ countItemsRecursively(item) }})
            </td>
          </tr>
        </template>

        <template v-slot:item.costs="{ item }">
          {{ item.costs === 0 ? "-" : item.costs.toLocaleString() + "&nbsp;€" }}
        </template>

        <template v-slot:item.type="{ item }">
          <BaseSensorBadge :sensors="item.sensor_types" />
        </template>

        <template v-slot:item.has_tco_support="{ item }">
          <v-icon :color="item.has_tco_support ? 'success' : 'error'">{{
            item.has_tco_support ? "mdi-check" : "mdi-minus"
          }}</v-icon>
        </template>

        <template v-slot:item.favourite="{ item }">
          <v-icon :color="is_favourite(item.id)">mdi-star</v-icon>
        </template>

        <template v-slot:item.guide_len="{ item }">
          <v-tooltip bottom v-if="item.customer_specific">
            <template v-slot:activator="{ props }">
              <span v-bind="props">
                <v-icon color="warning" class="ma-1" size="x-large"
                  >mdi-account-key</v-icon
                >
              </span>
            </template>
            {{ $t("system_request__customer_specific") }}
          </v-tooltip>
          <v-tooltip bottom v-if="item.guide.length > 0">
            <template v-slot:activator="{ props }">
              <span v-bind="props">
                <v-badge
                  inline
                  class="font-weight-light ml-3 ma-1"
                  :color="getColor(item.guide)"
                  :content="item.guide.length"
                  :value="item.guide.length > 0"
                >
                </v-badge>
              </span>
            </template>
            <div v-for="g in item.guide" :key="g.code">
              <v-icon v-if="g.type == 'info'" :color="g.type" left
                >mdi-information</v-icon
              >
              <v-icon v-else-if="g.type == 'warning'" :color="g.type" left
                >mdi-alert</v-icon
              >
              <v-icon v-else color="error" left>mdi-alert-octagon</v-icon>
              <span class="text--italic">{{ g.code }}: </span>
              <span class="font-weight-medium">{{
                $i18n.locale == "en" ? g.description_en : g.description
              }}</span>
            </div>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ props }">
              <span v-bind="props">
                <v-icon class="ma-1" color="green">mdi-check-all</v-icon>
              </span>
            </template>
            {{ $t("system_request__no_restrict") }}
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import BaseSensorBadge from "./BaseSensorBadge.vue";

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const showSortIcon = ref(false);
const showMenu = ref(false);
const selectedColumns = ref([]);
const { t } = useI18n();
const emits = defineEmits(["open_details"]);
const system_request = useSystemRequestStore();
const { project, solutions, project_active } = storeToRefs(system_request);
const toggleExpandBtns = ref({});
const settingsStore = useSettingsStore();
const { group_solutions } = storeToRefs(settingsStore);

const rowItemClass = function (item) {
  if (
    item.item.guide.filter(function (item) {
      return item.type === "error";
    }).length > 0
  ) {
    return { class: "bg-red-lighten-4" };
  } else if (
    item.item.guide.filter(function (item) {
      return item.type === "warning";
    }).length > 0
  ) {
    return { class: "bg-orange-lighten-4" };
  } else if (
    item.item.guide.filter(function (item) {
      return item.type === "info";
    }).length > 0
  ) {
    return { class: "bg-blue-lighten-5" };
  }
  return {};
};

const items = computed(() => {
  const result = solutions.value.map((el) => {
    return {
      ...el,
      "item-key": "name",
      type: el?.type == "OPS" ? "CLV" : el?.type, // TODO: rename type in backend OPS->CLV
      costs: el.costs["service"] + el.costs["system"],
      name: el.system_name ? el.system_name : el.name,
      guide_len: el.guide.length,
      system_category: el.system_category
        ? t(
            "system_request__categories_" +
              el.system_category.split(" + ").join("_").toLowerCase() +
              "_systems"
          )
        : "system_request__categories_other",
      sensor_types_str: el.sensor_types.join(" + "),
    };
  });
  return result;
});

const solutionTableHeaders = [
  { title: "systems_result_table__ID", value: "id", align: "start" },
  { title: "systems_result_table__type", value: "type", align: "end" },
  { title: "systems_result_table__name", value: "name", align: "start" },
  { title: "systems_result_table__notes", value: "notes", align: "start" },
  {
    title: "systems_result_table__conveyor_width",
    value: "max_conveyor_width",
    align: "center",
  },
  {
    title: "systems_result_table__object_height",
    value: "maximal_object_height",
    align: "center",
  },
  {
    title: "systems_result_table__material_number",
    value: "material_number",
    align: "center",
  },
  {
    title: "systems_result_table__type_code",
    value: "type_code",
    align: "center",
    divider: true,
  },
  {
    title: "systems_result_table__costs",
    value: "costs",
    align: "center",
    divider: true,
  },
  {
    title: "systems_result_table__tco_support",
    value: "has_tco_support",
    align: "center",
    divider: true,
  },
  {
    title: "systems_result_table__system_check",
    value: "guide_len",
    align: "center",
    divider: true,
  },
];

const headers = computed(() => {
  return solutionTableHeaders.filter((el) => {
    return settingsStore.show_system_price || el.value != "costs";
  });
});

const activeHeaders = computed(() => {
  let order = headers.value.map(({ title }) => title);
  const the_headers = selectedColumns.value.sort(function (a, b) {
    let i_a = order.indexOf(a.title);
    let i_b = order.indexOf(b.title);
    if (i_a < i_b) {
      return -1;
    }
    if (i_a > i_b) {
      return 1;
    }
    return 0;
  });
  if (project_active.value)
    return [
      ...the_headers,
      {
        title: "systems_result_table__selected_system",
        value: "favourite",
        align: "end",
        divider: true,
      },
    ];
  return the_headers;
});

const group_by = computed(() => {
  if (group_solutions.value == false) return [];
  return [
    {
      key: "system_category",
      order: "asc",
    },
    {
      key: "sensor_types_str",
      order: "asc",
    },
  ];
});

const sort_by = ref([
  {
    key: "guide_len",
    order: "asc",
  },
  {
    key: "costs",
    order: "asc",
  },
]);

function countItemsRecursively(item) {
  if (item.items) {
    return item.items.reduce(
      (acc, item) => acc + countItemsRecursively(item),
      0
    );
  }
  return 1;
}

function sendEmail() {
  const email =
    "mailto:PresalesSupport.AG-SystemSelector@sick.com?subject=[SSU] Customized system design needed&body=Project link: ";
  system_request.get_short_url().then((res) => {
    window.open(email + res);
  });
}

function allGroupsOpen() {
  for (const key in toggleExpandBtns.value) {
    if (
      !toggleExpandBtns.value[key].isGroupOpen(toggleExpandBtns.value[key].item)
    ) {
      return false;
    }
  }
  return true;
}

function openAll() {
  for (const key in toggleExpandBtns.value) {
    if (
      !toggleExpandBtns.value[key].isGroupOpen(toggleExpandBtns.value[key].item)
    ) {
      toggleExpandBtns.value[key].toggleGroup(toggleExpandBtns.value[key].item);
    }
  }
}

function closeAll() {
  for (const key in toggleExpandBtns.value) {
    if (
      toggleExpandBtns.value[key].isGroupOpen(toggleExpandBtns.value[key].item)
    ) {
      toggleExpandBtns.value[key].toggleGroup(toggleExpandBtns.value[key].item);
    }
  }
}

function is_favourite(id) {
  if (project.value && project.value.favourite == id) {
    return "yellow";
  }
  return "default";
}

function getColor(guide) {
  if (guide.length == 0) return "green";
  return guide[0].type;
}

function open_details(_, item) {
  emits("open_details", item.item);
}

watch(
  selectedColumns,
  () => {
    settingsStore.selected_columns = selectedColumns.value.map(
      (el) => el.value
    );
  },
  { deep: true }
);

onMounted(() => {
  selectedColumns.value = headers.value.filter((el) =>
    settingsStore.selected_columns.includes(el.value)
  );
});
</script>

<style scoped>
.flexbox_container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 125px);
}

.scrollable_content {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 125px);
}

.centered_container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  vertical-align: middle;
  margin: 0 auto;
  height: 20vh;
}
</style>
