import { defineStore } from "pinia";
import { ref, computed, watchEffect } from "vue";
import { useAPI } from "@/composables/useAPI";
import { v4 as uuidv4 } from "uuid";

export enum NotificationType {
    Info,
    Warning,
    Error,
    Success,
}

export const use_notification_center = defineStore(
    "notification_center",
    () => {
        const notifications = ref({});

        const recent = computed(() =>
            has_notifications ? Object.values(notifications.value)[0] : null
        );

        const has_notifications = computed(
            () => Object.entries(notifications.value).length > 0
        );

        function push_notification(
            title: String,
            subtitle: String,
            type: NotificationType
        ) {
            const id = uuidv4();

            notifications.value[id] = {
                id: id,
                title: title,
                subtitle: subtitle,
                type: type,
                timestamp: new Date().getTime(),
            };

            setTimeout(() => {
                delete notifications.value[id];
            }, 2000);
        }

        return { notifications, has_notifications, recent, push_notification };
    }
);
