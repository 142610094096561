<template>
  <v-app :theme="settings.getDarkMode">
    <TheAppBar
      v-if="userStore.isLoggedIn"
      app_name="Systemselektor"
      @toggle-menu="showMenu = !showMenu"
    />
    <TheNavigationDrawer
      v-if="userStore.isLoggedIn"
      v-model="showMenu"
      @logout="logout"
    />
    <v-main >
      <TheNotificationCenter />
      <router-view v-slot="slotProps" :key="$route.fullPath">
        <component :is="slotProps.Component"></component>
      </router-view>
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useSettingsStore } from "@/stores/settings";
import router from "@/router";
import TheNavigationDrawer from "@/components/TheNavigationDrawer.vue";
import TheFooter from "./components/TheFooter.vue";
import TheAppBar from "@/components/TheAppBar.vue";
import TheNotificationCenter from "./components/TheNotificationCenter.vue";
import { useUserStore } from "./stores/user";
import { useS3 } from "./composables/useS3";
import { useTheme } from "vuetify/lib/framework.mjs";

const theme = useTheme();
const settings = useSettingsStore();
const showMenu = ref(false);
const userStore = useUserStore();
const s3 = useS3();

const background = s3.get(s3.Bucket.PUBLIC, "background.jpg");

function logout() {
  userStore.clear();
  router.push("/login");
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.1s;
}

.fade-enter-active {
  transition-delay: 0.1s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
/*
.bg:after {
  content: "\A";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.251);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
} */

/* .bg-dark:after {
  content: "\A";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.9;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
} */


html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  overflow-y: hidden;
}

/* 
body {
  flex-grow: 1;
} */


</style>
