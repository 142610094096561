/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import { i18n } from './i18'
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from '@/router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueApexCharts from "vue3-apexcharts";

export function registerPlugins (app) {
  loadFonts()
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia)
  app.use(vuetify)
  app.use(i18n)
  app.use(router);
  app.use(VueApexCharts);
  app.use(VueAxios, axios)
}
