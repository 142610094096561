<template>
  <v-switch
    v-model="model"
    color="primary"
    @update:model-value="emit('switchClicked')"
    class="ml-1"
  >
  </v-switch>
  <!-- :label="
      $te('system_request_' + props.section_label + '_' + props.question.label)
        ? $t(
            'system_request_' + props.section_label + '_' + props.question.label
          )
        : props.question[settings.locale]
    " -->
</template>

<script setup>
import { useSettingsStore } from '@/stores/settings';
import { defineModel } from "vue";

const settings = useSettingsStore();
const props = defineProps(["question", "section_label"]);
const emit = defineEmits(["switchClicked"]);

const model = defineModel();
</script>

<style scoped></style>
