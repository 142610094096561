<template>
  <div class="my-n7 sensor-badge">
    <v-row no-gutters>
      <v-col v-for="s in sensors.slice(0, 3)">
        <v-badge
          dot
          :color="props.sensors.includes(s) ? sensorColor[s] : 'grey-lighten-2'"
        ></v-badge>
      </v-col>
    </v-row>
    <v-row no-gutters class="my-n2">
      <v-col v-for="s in sensors.slice(3, sensors.length + 1)">
        <v-badge
          dot
          :color="props.sensors.includes(s) ? sensorColor[s] : 'grey-lighten-2'"
        ></v-badge>
      </v-col>
    </v-row>
  </div>

  <v-tooltip activator="parent" class="mt-8">
    <v-row
      no-gutters
      v-for="s in sensors.filter((s) => props.sensors.includes(s))"
    >
      <v-col>
        <v-badge dot :color="sensorColor[s]" class="pb-2 pr-2"></v-badge>
        {{ s }}
      </v-col>
    </v-row>
  </v-tooltip>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps(["sensors"]);

const sensorColor = {
  ICR: "red",
  Lector: "blue",
  CLV: "yellow",
  RFID: "green",
  VMS: "orange",
  OPS: "purple",
  Scale: "grey",
};

const sensors = ["ICR", "Lector", "CLV", "RFID", "VMS", "Scale"];
const tooltip = computed(() => {
  if (props.sensors.length === 0) {
    return "No sensors";
  }
  return sensors.filter((s) => props.sensors.includes(s)).join(", ");
});
</script>

<style scoped>
.sensor-badge {
  max-width: 35px;
}
</style>
