<template>
  <v-sheet
    class="pa-5 d-flex align-center justify-start"
    style="min-height: 300px"
  >
    <v-list>
      <v-list-subheader>Options</v-list-subheader>

      <v-list-item
        v-for="item in settings_items"
        :key="item"
        :title="item.title"
        :subtitle="item.subtitle"
      >
        <template v-slot:append>
            <v-switch class="ml-5" color="primary"></v-switch>
          <!-- <v-btn
            class="ml-5"
            color="grey-lighten-1"
            icon="mdi-information"
            variant="text"
          ></v-btn> -->
        </template>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>

<script setup>
import { ref } from "vue";

const settings_items = ref([
  {
    title: "Default language",
    subtitle: "The languages set by default. And then some more",
  },
  {
    title: "Show price in generated reports (PDF/DOCX)",
    subtitle: "Show the price in the generated reports.",
  },
  {
    title: "Show price in solutions table",
    subtitle: "Show the price in the solutions table.",
  },
  {
    title: "Dark Mode?",
    subtitle: "default theme",
  },
  {
    title: "Selected columns",
    subtitle: "Select the columns you want to see in the solutions table",
  },
  {
    title: "Group systems in solutions table",
    subtitle: "Group the systems in the solutions table",
  },
]);
</script>
