<template>
  <v-bottom-sheet v-model="dialog" inset>
    <v-card>
      <slot name="title">
        <v-card-title>{{ $t("delete_prompt__title") }}</v-card-title>
      </slot>

      <slot name="subtitle">
        <v-card-subtitle>{{
          $t("delete_prompt__subtitle", { project_name: item.name })
        }}</v-card-subtitle>
      </slot>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot color="primary" name="cancel_button">
          <v-btn @click="cancel_clicked">{{
            $t("delete_prompt__cancel")
          }}</v-btn>
        </slot>

        <slot name="delete_button">
          <v-btn @click="delete_clicked" color="error">{{
            $t("delete_prompt__delete")
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script setup>
import { ref } from "vue";

defineExpose({ open });

const item = ref(null);
const dialog = ref(false);
var resolve = null;
var reject = null;

function open(_item) {
  return new Promise((resolve_, reject_) => {
    resolve = resolve_;
    reject = reject_;
    item.value = _item;
    dialog.value = true;
  });
}

function cancel_clicked() {
  dialog.value = false;
  reject();
}

function delete_clicked() {
  dialog.value = false;
  resolve(item);
}
</script>
