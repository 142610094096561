<template>
  <v-container>
    <ProjectDeletePrompt ref="delete_prompt" />
    <ProjectActionPrompt ref="project_prompt" />
    <v-card>
      <v-data-table-server
        :search="searchTerm"
        :headers="header"
        :items="items"
        :items-length="totalItems"
        :items-per-page="20"
        @update:options="options = $event"
        @click:row="item_clicked"
      >
        <template v-slot:top>
          <v-toolbar color="transparent">
            <v-text-field
              class="pl-4"
              prepend-icon="mdi-magnify"
              :label="$t('search')"
              v-model="searchTerm"
              variant="underlined"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-4"
              prepend-icon="mdi-plus"
              color="primary"
              @click="router.push({ path: '/systemselektor/request/' })"
              >{{ $t("systems__create_system") }}</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.last_updated="{ item }">
          {{ formatDate(item.last_updated) }}
        </template>

        <template v-slot:item.name="{ item }">
          <span class="mr-2">
            {{ item.name }}
          </span>
          <span
            v-if="item.author_name != user.username"
            class="text-caption text-grey"
          >
            (geteilt von {{ item.author_name }})
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu location="start" transition="scale-transition">
            <template v-slot:activator="{ props }">
              <v-btn
                @click.stop=""
                icon="mdi-dots-horizontal"
                variant="text"
                color="primary"
                v-bind="props"
              >
              </v-btn>
            </template>
            <v-card
              class="pa-3"
              style="
                border-radius: 100px !important;
                transform: translate(-10px, -10px);
              "
            >
              <v-row class="pa-2">
                <v-tooltip :text="$t('systems__edit_survey')" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-pencil-outline"
                      color="orange"
                      variant="text"
                      v-bind="props"
                      @click="edit_item(item)"
                    ></v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip :text="$t('systems__copy_survey')" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-content-copy"
                      color="primary"
                      variant="text"
                      v-bind="props"
                      @click="copy_item(item)"
                    ></v-btn>
                  </template>
                </v-tooltip>
                <!-- <v-tooltip
                  :text="$t('systems__edit_permissions')"
                  location="top"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-account-key-outline"
                      color="success"
                      variant="text"
                      v-bind="props"
                      @click="set_permissions(item)"
                    ></v-btn>
                  </template>
                </v-tooltip> -->
                <v-tooltip :text="$t('systems__delete_survey')" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-delete-outline"
                      color="error"
                      variant="text"
                      v-bind="props"
                      @click="delete_item(item)"
                    ></v-btn>
                  </template>
                </v-tooltip>
              </v-row>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:expanded-row="{ columns, item }">
          <tr>
            <td :colspan="columns.length">
              <span class="text-caption ml-2 text-grey">
                <span v-if="item.description.length > 0">
                  {{ item.description }}
                </span>
                <span v-else> {{ $t("systems__no_description") }}</span>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table-server>
    </v-card>
  </v-container>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAPI } from "@/composables/useAPI";
import { ref, watch, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import {
  use_notification_center,
  NotificationType,
} from "@/stores/notification_center";
import ProjectActionPrompt from "@/components/ProjectActionPrompt.vue";
import { useSystemRequestStore } from "@/stores/system_request";

const { t } = useI18n();
const user = useUserStore();
const api = useAPI();
const router = useRouter();
const notification_center = use_notification_center();
const header = [
  { title: t("systems__name"), key: "name", sortable: false },
  { title: t("systems__customer"), key: "customer", sortable: false },
  {
    title: t("systems__edit_date"),
    align: "center",
    key: "last_updated",
    sortable: false,
    width: "220px",
  },
  { title: "", align: "end", key: "actions", sortable: false, width: "75px" },
];
var totalItems = ref(0);
var items = ref([]);
var options = ref({});
var searchTerm = ref("");
const system_request = useSystemRequestStore();

const delete_prompt = ref(null);
const project_prompt = ref(null);

function formatDate(date) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("de-DE", options);
}

watch(options, (_) => {
  getDataFromApi();
});

function getDataFromApi() {
  const { _, page, itemsPerPage, search } = options.value;
  api.projectAPI
    .projectList(search, undefined, page, itemsPerPage)
    .then((res) => {
      items.value = res.data.results;
      totalItems.value = res.data.count;
    })
    .catch((err) => {
      notification_center.push_notification(
        "error",
        err,
        NotificationType.Error
      );
    });
}

function item_clicked(_, event) {
  router.push({
    path: "/systemselektor/request/" + items.value[event.index].id,
  });
}

function delete_item(item) {
  delete_prompt.value
    .open(item)
    .then((item) => {
      return api.projectAPI.projectDelete(item.value.id);
    })
    .then(() => {
      const index = items.value.findIndex((e) => {
        return e.id === item.id;
      });
      items.value.splice(index, 1);
    })
    .then(() => {
      notification_center.push_notification(
        t("systems__project_deleted"),
        "",
        NotificationType.Success
      );
    })
    .catch((err) => {
      if (err) {
        notification_center.push_notification(
          t("systems__project_deleted_failed"),
          err,
          NotificationType.Error
        );
      }
    });
}

function set_permissions(item) {}

function copy_item(item) {
  system_request.copy_dialog(project_prompt, item.definition).then((res) => {
    getDataFromApi();
  });
}

function edit_item(item) {
  system_request.edit_dialog(project_prompt, item).then((res) => {
    const index = items.value.findIndex((e) => {
      return e.id === item.id;
    });
    items.value[index].name = res.name;
    items.value[index].description = res.description;
    items.value[index].customer = res.customer;
  });
}
</script>

<style></style>
