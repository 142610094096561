<template>
  <div class="mt-5 ml-3">
    <v-slider
      color="primary"
      v-model="model"
      :step="props.question.step"
      :max="props.question.max"
      :min="props.question.min"
      thumb-label="always"
      @end="emit('sliderReleased')"
    >
      <template v-slot:append>
        <p style="font-size: 0.75em" class="grey--text text-center">
          {{ unit }}
        </p>
      </template>
    </v-slider>
  </div>
</template>

<script setup>
import { defineModel, computed } from "vue";

const emit = defineEmits(["sliderReleased"]);

const model = defineModel();
const props = defineProps(["question", "section_label"]);

const unit = computed(() => {
  return props.question.unit;
});
</script>

<style scoped></style>
